/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserInvitation from '../../models/UserInvitation';
import PlatformService from '../../services/PlatformService';
import { isEmailValid } from '../../utils/EmailUtils';
import { Option } from '../Option';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import { InputSuggestion } from '../shared/form-control/InputSuggestions';
import usePermissions from '../../hooks/permissions/usePermissions';
import AdminService from '../../services/AdminService';
import LanguageSelector from '../shared/LanguageSelector';
import { systemDefaultLanguageCode } from '../../types/Languages';
import { Roles } from '../../models/Role';
import { ClientFormUserRoleValues } from '../../models/ClientFormUserRoles';
import { useCurrentClient } from '../../global-state/Clients';
import { defaultMemberFields, MemberField, MemberFieldKey } from '../../models/ClientMemberFields';
import { FieldRenderer } from './FieldRenderer';
import { UserClientSpecificCustomData, UserProfile } from '../../models/AuthModels';

type InviteOrSearchContentProps = {
  exisitingUsers?: Option<string, string>[];
  formRoles?: Option<string, string | number>[];
  selectedFormRole?: Option<string, string | number>;
  inviteMode: boolean;
  filterRoles: (role: Option<string, string>) => boolean;
  onFormRoleChange?: (formRole: Option<string, string | number>) => void;
  onInputsValid: (isValid: boolean) => void;
  onUserChange: (user: UserInvitation) => void;
  clientId: string | null | undefined;
};

const EMPTY_USER: UserInvitation = {
  email: '',
  role: Roles.Employee,
  firstName: '',
  lastName: '',
  clientId: '',
  clientName: '',
  languageCode: systemDefaultLanguageCode,
  address: undefined,
  phoneNumber: undefined,
  birthday: '',
  clientCustomData: {},
};

const errorMessages = {
  required: 'common:validation.required',
  email: 'common:validation.email',
} as const;

const validateField = (key: string, value: any, isRequired: boolean): (typeof errorMessages)[keyof typeof errorMessages] | null => {
  if (isRequired && !value) {
    return errorMessages.required;
  }
  if (key === MemberFieldKey.Email && value && !isEmailValid(value)) {
    return errorMessages.email;
  }
  return null;
};

const InviteOrSearchContent: FC<InviteOrSearchContentProps> = (props) => {
  const { exisitingUsers, filterRoles, inviteMode, onInputsValid, onUserChange, selectedFormRole, formRoles, onFormRoleChange, clientId } = props;
  const { t, i18n } = useTranslation(['common']);
  const [platformRoles, setPlatformRoles] = useState<Option<string, string>[]>([]);
  const [inviteUser, setInviteUser] = useState({ ...EMPTY_USER, languageCode: i18n.language });
  const [addUser, setAddUser] = useState({ ...EMPTY_USER, languageCode: i18n.language });
  const [addSearch, setAddSearch] = useState('');
  const [clients, setClients] = useState<Option<string, string>[]>([]);
  const hasPermission = usePermissions();
  const currentClient = useCurrentClient((x) => x.value);
  const [errors, setErrors] = useState<Record<string, (typeof errorMessages)[keyof typeof errorMessages] | null>>({});

  const memberFields = useMemo(() => {
    return currentClient?.memberFields?.length
      ? currentClient.memberFields
      : defaultMemberFields.filter((field) => field.isRequired && field.isEditable);
  }, [currentClient]);

  useEffect(() => {
    PlatformService.getRoles().then((res) => {
      setPlatformRoles(res.data.map((role) => ({ id: role, text: t(`roles.${role}` as any), value: role })).filter(filterRoles));
    });
  }, [filterRoles, t]);

  useEffect(() => {
    if (!clientId) {
      AdminService.getAccounts({ pageSize: 1000 }).then((res) => {
        setClients(res.data.map((account) => ({ id: account.id, text: account.name, value: account.id })));
      });
    }
  }, [clientId]);

  useEffect(() => {
    setInviteUser({ ...EMPTY_USER, languageCode: currentClient?.language || i18n.language });
    setAddUser({ ...EMPTY_USER, languageCode: currentClient?.language || i18n.language });
    setAddSearch('');
  }, [currentClient?.language, i18n.language, inviteMode]);

  const onFieldChange = useCallback(
    (key: string, value: any, isRequired: boolean, fieldInfo: MemberField | null) => {
      if (fieldInfo?.isUserField || key == 'languageCode') {
        setInviteUser((prev) => ({ ...prev, [key]: value }));
      } else {
        setInviteUser((prev) => ({
          ...prev,
          clientCustomData: {
            ...(prev.clientCustomData ?? {}),
            [currentClient!.id]: {
              ...(prev.clientCustomData?.[currentClient!.id] ?? {}),
              [key]: value,
            },
          },
        }));
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [key]: validateField(key, value, isRequired),
      }));
    },
    [currentClient],
  );

  useEffect(() => {
    onFieldChange('clientId', clientId || '', true, null);
  }, [clientId, onFieldChange]);

  const invitePermissionsOptions = useMemo(() => {
    if (!platformRoles.length) {
      return [];
    }
    return platformRoles.filter((x) => filterRoles(x) && hasPermission(x.value)).sort((a, b) => a.text.localeCompare(b.text));
  }, [filterRoles, hasPermission, platformRoles]);

  const addOnPick = useCallback(
    (option: Option<string, string | number>) => {
      setAddSearch(option.text);

      // first & last name in firstName field because we can't split it easily. Server will have the correct values
      // Role isn't used when associating a user with a client
      setAddUser({
        id: option.id,
        email: option.value as string,
        firstName: option.text,
        lastName: '',
        role: '',
        clientId: clientId || '',
        languageCode: currentClient?.language || systemDefaultLanguageCode,
      });
    },
    [clientId, currentClient?.language],
  );

  useEffect(() => {
    if (inviteMode) {
      const isClientValid = (!clientId && !!inviteUser.clientId) || !!clientId;
      const isValid =
        isClientValid &&
        memberFields.every((field) => {
          if (field.isEditable && ![MemberFieldKey.BuilderAccess].includes(field.key)) {
            const error = validateField(
              field.key,
              field.key in inviteUser
                ? inviteUser[field.key as keyof UserProfile]
                : inviteUser.clientCustomData?.[clientId || inviteUser.clientId]?.[field.key as keyof UserClientSpecificCustomData],
              field.isRequired,
            );

            return !error;
          }
          return true;
        });

      onInputsValid(isValid);
    } else {
      onInputsValid(!!addUser.id);
    }
  }, [addUser.id, clientId, inviteMode, inviteUser, memberFields, onInputsValid]);

  useEffect(() => {
    onUserChange(inviteMode ? inviteUser : addUser);
  }, [addUser, inviteUser, inviteMode, onUserChange]);

  return inviteMode ? (
    <div data-cy="invite-user">
      {memberFields.map((field) => {
        const renderer = FieldRenderer[field.key as MemberFieldKey];
        if (!renderer) return null;
        return (
          <Fragment key={field.key}>
            {renderer({
              field,
              userProfile: inviteUser,
              errors,
              onFieldChange,
              t,
              invitePermissionsOptions,
              clientId: currentClient!.id,
            })}
          </Fragment>
        );
      })}

      {!clientId && (
        <DropdownSelect
          data-cy="platform-permission"
          wrapperClassName="mt-8 w-full"
          placeholder={t('add-or-invite-modal.invite.organisation')}
          label={t('add-or-invite-modal.invite.organisation')}
          aria-label={t('add-or-invite-modal.invite.organisation')}
          options={clients}
          onChange={(o) => {
            onFieldChange('clientId', o.value as string, true, null);
            onFieldChange('clientName', o.text as string, true, null);
          }}
          value={{
            id: inviteUser.clientId || '',
            value: inviteUser.clientId || '',
            text: clients.find((x) => x.value === inviteUser.clientId)?.text || '',
          }}
          required
        />
      )}

      {formRoles && (
        <DropdownSelect
          wrapperClassName="mt-8 w-full"
          data-cy="form-permission"
          value={selectedFormRole}
          onChange={(data) => {
            onFormRoleChange && onFormRoleChange(data);
            onFieldChange('defaultClientFormRole', data.value as ClientFormUserRoleValues, true, null);
          }}
          options={formRoles}
          placeholder={t('add-or-invite-modal.form-role-placeholder')}
          label={t('add-or-invite-modal.form-role-placeholder')}
          aria-label={t('add-or-invite-modal.form-role-placeholder')}
          disabled={formRoles.length <= 1}
          required
        ></DropdownSelect>
      )}

      <div className="mt-8 w-full">
        <LanguageSelector
          setCurrentUserLanguage={false}
          onLanguageChange={(code) => onFieldChange('languageCode', code, true, null)}
          label={t('add-or-invite-modal.invite.language')}
          value={inviteUser.languageCode}
        />
      </div>
    </div>
  ) : (
    <div data-cy="add-user">
      {!!exisitingUsers && (
        <div className="flex flex-col gap-4">
          <InputSuggestion
            data-cy="user-search"
            inputConfig={{
              value: addSearch,
              placeholder: t('add-or-invite-modal.add.search'),
            }}
            onChange={setAddSearch}
            onPick={addOnPick}
            suggestions={exisitingUsers}
          />

          {formRoles && (
            <DropdownSelect
              wrapperClassName="mt-8 w-full"
              data-cy="form-permission"
              value={selectedFormRole}
              onChange={(data) => onFormRoleChange && onFormRoleChange(data)}
              options={formRoles}
              placeholder={t('add-or-invite-modal.form-role-placeholder')}
              label={t('add-or-invite-modal.form-role-placeholder')}
              aria-label={t('add-or-invite-modal.form-role-placeholder')}
              disabled={formRoles.length <= 1}
            ></DropdownSelect>
          )}
        </div>
      )}
    </div>
  );
};

export default InviteOrSearchContent;
